@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Resets & Globals */
*,
html,
body,
ul,
ol,
li,
img,
p,
a {
  border: 0;
  box-sizing: border-box;
  font-family: var(--font-nunito);
  line-height: 1;
  list-style-image: none;
  list-style-type: none;
  margin: 0;
  outline: 0;
  padding: 0;
  text-decoration: none;
}

html,
body {
  font-size: 16px;
  background-color: #000;
}

a,
a:hover {
  transition: all 0.2s cubic-bezier(0.5, 0, 0.5, 1);
}

/* h1 {
  font-size: max(1.65em, 3vw);
  margin-bottom: 2.5rem;
  font-weight: 900;
  letter-spacing: -0.03em;
  line-height: 1.25;
} */

@keyframes spin {
  0% {
    transform: rotate(0deg) translateX(0) translateY(0);
  }
  50% {
    transform: rotate(180deg) translateX(random(200) + px)
      translateY(random(200) + px);
  }
  100% {
    transform: rotate(360deg) translateX(0) translateY(0);
  }
}
